/** @format */

import { useRouter } from "next/router";
import { useEffect } from "react";
import { useAuth } from "../utils/context";

/* Bei eingeloggtem User wird an das Profil weitergeleitet, ansonsten zum Login */
export default function Home() {
  const user = useAuth();
  const router = useRouter();
  useEffect(() => {
    if (!user.init) {
      if (user.user) {
        router.push("/profil");
      } else if (window?.location?.hostname === "plattform.carenext.net") {
        router.push("/login");
      } else {
        router.push("/suche");
      }
    }
  }, [user.init]);
  return <></>;
}
